import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SelectInput from "@/view/components/SelectInput.vue";
import { trimStart } from "lodash";
export default {
  name: "text-phone-input",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    selectClass: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      countries: [{
        text: "SG",
        value: "+65"
      }, {
        text: "MY",
        value: "+60"
      }, {
        text: "AU",
        value: "+61"
      }],
      countryCode: "+65",
      textinput: null
    };
  },
  components: {
    SelectInput: SelectInput
  },
  watch: {
    value: function value() {
      if (this.value) {
        var array = this.value.split("-");
        this.countryCode = array[0] ? array[0] : null;
        this.textinput = array[1] ? array[1] : null;
      } else {
        this.countryCode = "+65";
        this.textinput = null;
      }
    },
    countryCode: function countryCode() {
      var value = trimStart(this.textinput, "-");
      this.$emit("input", "".concat(this.countryCode, "-").concat(value));
    },
    textinput: function textinput() {
      var value = trimStart(this.textinput, "-");
      this.$emit("input", "".concat(this.countryCode, "-").concat(value));
    }
  },
  computed: {
    getPrefix: function getPrefix() {
      return "".concat(this.countryCode, "-");
    }
  },
  mounted: function mounted() {
    if (this.value) {
      var array = this.value.split("-");
      this.countryCode = array[0] ? array[0] : null;
      this.textinput = array[1] ? array[1] : null;
    }
  }
};