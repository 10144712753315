import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import Phone from "@/view/components/Phone";
import ObjectPath from "object-path";
import { POST, PATCH } from "@/core/services/store/request.module";
import { ERROR } from "@/core/services/store/common.module";
import { passwordStrength } from "check-password-strength";
export default {
  name: "add-user",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    drawer: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.init();
      }
    }
  },
  data: function data() {
    return {
      show_password: false,
      form_valid: true,
      userUUID: null,
      roles: [{
        text: "Admin",
        value: "admin"
      }, {
        text: "User",
        value: "user"
      }],
      loading: false,
      first_name: null,
      last_name: null,
      display_name: null,
      password: null,
      phone_number: null,
      email: null,
      role: null
    };
  },
  components: {
    AutoCompleteInput: AutoCompleteInput,
    EmailInput: EmailInput,
    TextInput: TextInput,
    Phone: Phone
  },
  methods: {
    validate_password: function validate_password(password) {
      return passwordStrength(password);
    },
    update_or_create: function update_or_create() {
      var _this = this;

      this.$refs.userForm.validate();
      var formErrors = this.validateForm(this.$refs.userForm);

      if (formErrors.length) {
        this.$store.dispatch(ERROR, formErrors);
        return false;
      }

      if (!this.$refs.userForm.validate()) {
        return false;
      }

      var requestType = POST;
      var requestURL = "users";

      if (this.userUUID) {
        requestType = PATCH;
        requestURL = "users/".concat(this.userUUID);
      }

      var request = {
        first_name: this.first_name,
        last_name: this.last_name,
        display_name: this.display_name,
        phone_number: this.phone_number,
        password: this.password,
        email: this.email
      };
      this.loading = true;
      this.$store.dispatch(requestType, {
        url: requestURL,
        data: request
      }).then(function () {
        if (_this.userUUID) {
          _this.$emit("select", _this.userUUID);
        }

        _this.$emit("success", true);

        _this.$emit("close", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    },
    init: function init() {
      this.userUUID = ObjectPath.get(this.value, "uuid", null);
      this.first_name = ObjectPath.get(this.value, "first_name");
      this.last_name = ObjectPath.get(this.value, "last_name");
      this.display_name = ObjectPath.get(this.value, "display_name");
      this.phone_number = ObjectPath.get(this.value, "phone_number");
      this.email = ObjectPath.get(this.value, "email");
    }
  },
  mounted: function mounted() {
    this.init();
  }
};