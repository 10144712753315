import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AddUser from "@/view/pages/components/AddUser";
import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import ObjectPath from "object-path";
import { cloneDeep, find } from "lodash";
import { ERROR } from "@/core/services/store/common.module";
import { passwordStrength } from "check-password-strength";
export default {
  name: "users",
  components: {
    AddUser: AddUser,
    Dialog: Dialog,
    TextInput: TextInput
  },
  data: function data() {
    return {
      selectedItem: 0,
      s_loading: false,
      status_dialog: false,
      new_password: null,
      show_new: false,
      confirm_password: null,
      show_confirm: false,
      old_password: null,
      show_old: false,
      form_valid: true,
      add_new_user: false,
      p_loading: false,
      loading: false,
      users: [],
      user: {},
      e_user: {},
      update_password_dailog: false,
      filter: {
        "page-no": 1,
        "page-limit": 50,
        search: null
      },
      status: "active",
      status_count: {
        active: 0,
        inactive: 0
      },
      t_uuid: null,
      timeout: null,
      timeoutLimit: 200
    };
  },
  watch: {
    $route: function $route() {
      this.search();
    }
  },
  methods: {
    search: function search() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        _this.get_users();
      }, _this.timeoutLimit);
    },
    validate_password: function validate_password(password) {
      return passwordStrength(password);
    },
    update_status: function update_status() {
      var _this2 = this;

      this.s_loading = true;
      this.$store.dispatch(PATCH, {
        url: "users/".concat(this.user.uuid, "/status")
      }).then(function () {
        _this2.get_users();
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this2.s_loading = false;
      });
    },
    update_password: function update_password() {
      var _this3 = this;

      this.$refs.passwordForm.validate();
      var formErrors = this.validateForm(this.$refs.passwordForm);

      if (formErrors.length) {
        this.$store.dispatch(ERROR, formErrors);
        return false;
      }

      if (!this.$refs.passwordForm.validate()) {
        return false;
      }

      if (this.new_password != this.confirm_password) {
        this.$store.dispatch(ERROR, [{
          model: true,
          message: "Confirm password doesn't matched with new password.",
          timeout: 2000
        }]);
        return false;
      }

      var request = {
        old_password: this.old_password,
        password: this.new_password,
        password_confirmation: this.confirm_password
      };
      this.p_loading = true;
      this.$store.dispatch(PATCH, {
        url: "users/".concat(this.user.uuid, "/password"),
        data: request
      }).then(function () {
        _this3.update_password_dailog = false;
        _this3.old_password = null;
        _this3.new_password = null;
        _this3.confirm_password = null;
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this3.p_loading = false;
      });
    },
    edit_user: function edit_user() {
      this.add_new_user = true;
      this.e_user = cloneDeep(this.user);
    },
    select_user: function select_user(row) {
      this.user = row;
    },
    select_row: function select_row(uuid) {
      this.t_uuid = uuid;
    },
    get_users: function get_users() {
      var _this4 = this;

      this.loading = true;
      this.update_password_dailog = false;
      this.status_dialog = false;
      this.add_new_user = false;
      this.e_user = {};
      this.users = [];
      this.user = {};
      this.filter["status"] = this.status;
      this.filter["page-no"] = ObjectPath.get(this.$route, "query.page-no", 1);
      this.filter["page-limit"] = ObjectPath.get(this.$route, "query.page-limit", 50);
      this.filter["search"] = ObjectPath.get(this.$route, "query.search-item-name", null);
      this.$store.dispatch(QUERY, {
        url: "users",
        data: this.filter
      }).then(function (response) {
        _this4.status_count.inactive = ObjectPath.get(response, "status.deactive", 0);
        _this4.status_count.active = ObjectPath.get(response, "status.active", 0);
        _this4.users = ObjectPath.get(response, "rows", []);
        _this4.user = ObjectPath.get(_this4.users, "0", {});
        _this4.filter["page-no"] = ObjectPath.get(response, "page-no");
        _this4.filter["page-limit"] = ObjectPath.get(response, "page-limit");

        if (_this4.t_uuid) {
          var t_row = find(_this4.users, {
            uuid: _this4.t_uuid
          });

          if (t_row) {
            _this4.select_user(t_row);
          }

          _this4.$nextTick(function () {
            _this4.t_uuid = null;
          });
        }
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this4.loading = false;
      });
    }
  },
  mounted: function mounted() {
    this.get_users();
  }
};