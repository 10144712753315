import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "select-input",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: [Object, Number, String, Array],
      default: null
    },
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    rules: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    customClass: {
      type: String,
      default: "mt-3 pt-0"
    },
    placeholder: {
      type: String,
      default: null
    },
    appendOuterIcon: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.selectinput = param;
      }
    }
  },
  data: function data() {
    return {
      selectinput: null
    };
  },
  mounted: function mounted() {
    this.selectinput = this.value;
  }
};