import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "autocomplete-input",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: [Object, Number, String, Array],
      default: null
    },
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    rules: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    prependInnerIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: "mdi-menu-down"
    },
    appendOuterIcon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    timePicker: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(value) {
        this.autocompleteinput = value;
      }
    }
  },
  data: function data() {
    return {
      autocompleteinput: null,
      menuProps: {}
    };
  },
  methods: {
    getPlaceholder: function getPlaceholder() {
      return "Select ".concat(this.placeholder);
    }
  },
  mounted: function mounted() {
    this.autocompleteinput = this.value;

    if (this.dense) {
      this.menuProps.contentClass = "dense-autocomplete-list";
    }

    if (this.timePicker) {
      this.menuProps.contentClass = "time-picker-autocomplete-list";
    }
  }
};