//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: null
    },
    rules: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      textinput: null
    };
  },
  watch: {
    value: function value() {
      this.textinput = this.value;
    },
    textinput: function textinput() {
      this.$emit("input", this.textinput);
    }
  },
  mounted: function mounted() {
    this.textinput = this.value;
  }
};